@import '@angular/material/prebuilt-themes/indigo-pink.css';

body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.basic-container {
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

.version-info {
  font-size: 8pt;
  float: right;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #F25D22!important;
}

.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #F25D22;
  color: #FFFFFF;
}

body {
  padding-top: 72px;
}
